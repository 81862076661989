import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import shuffle from "lodash/shuffle";
import promiseMemo from "promise-memoize";
import { useApi } from "../../spages/spa/context/ApiContext";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { useTracker } from "../../spages/spa/context/TrackerContext";
import { LANDLORD_INTENT_LOCATION } from "../../tracker/constants";
import Accordion from "../Accordion/Accordion";
import WunderflatsLogo from "../WunderflatsLogo/WunderflatsLogo";

const propTypes = {
  url: PropTypes.func.isRequired,
  user: PropTypes.object,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  ),
  className: PropTypes.string,
  districts: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  ),
  showRefugeeDistributionFeatures: PropTypes.bool,
};

const getRegionsMemo = promiseMemo(
  async (api) => {
    return api.listings.getRegions();
  },
  { maxAge: 60000 },
);

const shuffleRegions = (regionsArray) => {
  if (!regionsArray) {
    return;
  }

  const shuffledRegions = shuffle(
    regionsArray
      .filter((r) => !r.disabled)
      .filter((r) => !r.parentSlug) // filter out districts of other regions
      .map((r) => ({ ...r, slug: r.slug })),
  );

  const viennaIndex = shuffledRegions.findIndex(
    (region) => region.slug === "vienna",
  );
  if (viennaIndex >= 0) {
    const vienna = shuffledRegions[viennaIndex];
    shuffledRegions[viennaIndex] = shuffledRegions[0];
    shuffledRegions[0] = vienna;
  }

  return shuffledRegions.slice(0, 16);
};

const Footer = React.forwardRef(
  (
    {
      url,
      regions = [],
      user,
      className,
      districts,
      showRefugeeDistributionFeatures,
    },
    ref,
  ) => {
    const [shuffledRegions, setShuffledRegions] = useState([]);
    const { tracker } = useTracker();

    const api = useApi();
    const { t, lang } = useI18n();
    const defaultDestinations = [
      "berlin",
      "munich",
      "hamburg",
      "frankfurt",
      "dusseldorf",
    ];

    const DefaultDestinations = () => (
      <ul className="Footer-accordionSection">
        {defaultDestinations.map((region, index) => (
          <li
            className={`Footer-accordionListItem ${
              index === 0 && "Footer-listItem--noMarginTop"
            }`}
            key={region}
          >
            <a
              className="Footer-link"
              href={url("listinglist", { region, lang })}
            >
              <span className="city-name">{t(`cities.${region}.name`)}</span>
            </a>
          </li>
        ))}
      </ul>
    );

    const DistrictDestinations = () => (
      <ul className="Footer-DestinationGrid">
        {districts.map((district) => {
          return (
            <li className="Footer-accordionListItem" key={district.slug}>
              <a
                className="Footer-link"
                href={url("listinglist", { region: district.slug, lang })}
              >
                <span className="city-name">
                  {t(`regions.${district.slug}`)}
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    );

    const Destinations = () =>
      districts?.length > 0 ? (
        <DistrictDestinations />
      ) : (
        <DefaultDestinations />
      );

    useEffect(() => {
      if (districts && districts.length > 0) {
        return setShuffledRegions(districts);
      }

      const fetchRegions = async () => {
        const result = await getRegionsMemo(api);
        setShuffledRegions(shuffleRegions(result?.items));
      };

      if (!regions.length) {
        fetchRegions();
      } else {
        setShuffledRegions(shuffleRegions(regions));
      }
      // This useEffect should only be run on first render
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLandlord = user && user.landlord;

    const refugeeSupportContactPhone = t(
      "contactDetails.refugeeSupport.phoneHref",
    );

    const formattedContactPhone = isLandlord
      ? t("contactDetails.landlords.phoneFormatted")
      : t("contactDetails.support.phoneFormatted");
    const contactEmailHref = isLandlord
      ? t("contactDetails.landlords.emailHref")
      : t("contactDetails.support.emailHref");
    const contactPhoneHref = isLandlord
      ? t("contactDetails.landlords.phoneHref")
      : t("contactDetails.support.phoneHref");

    const jobsLinkUrl =
      lang === "fr"
        ? "https://boards.eu.greenhouse.io/wunderflatsfr"
        : "https://boards.eu.greenhouse.io/wunderflats";

    /** We only have affiliate links for English and German. If you ever need to enable it for other languages,
     * please update the translations for the "components.Footer.more.affiliateProgram" key.
     */
    const showAffiliateProgramLink = lang === "de" || lang === "en";

    return (
      <footer
        ref={ref}
        data-testid="MainFooter"
        className={classnames("Footer", className)}
      >
        <div
          className={classnames("Footer-container", {
            "Footer-container--withoutRegions": showRefugeeDistributionFeatures,
          })}
        >
          {!showRefugeeDistributionFeatures && (
            <div className="Footer-cities" data-testid="Footer-cities">
              {shuffledRegions.map((region, key) => (
                <a
                  className="Footer-cityItem"
                  href={url("listinglist", {
                    region: region.slug,
                    lang,
                  })}
                  key={key}
                >
                  {t(`regions.${region.slug}`)}
                  {region.isNew && (
                    <span className="Footer-cityItem--new">{t("new")}</span>
                  )}
                </a>
              ))}
            </div>
          )}

          <div
            className={classnames("Footer-logoAndLinks", {
              "Footer-logoAndLinks--withoutRegions":
                showRefugeeDistributionFeatures,
            })}
          >
            <figure className="Footer-logo">
              <a
                href={url("home")}
                title={t("homepage")}
                aria-label={t("homepage")}
              >
                <WunderflatsLogo
                  id="WunderflatsLogo-Footer"
                  whiteText
                  width="175"
                  height="44"
                />
              </a>
            </figure>
            <ul className="Footer-companyLinks">
              <li className="Footer-companyLinkItem">
                <a className="Footer-link" target="_blank" href={url("about")}>
                  {t("components.Footer.more.about")}
                </a>
              </li>
              <li className="Footer-companyLinkItem">
                <a
                  className="Footer-link"
                  target="_blank"
                  href="https://boards.eu.greenhouse.io/wunderflats"
                >
                  {t("components.Footer.more.jobs")}
                </a>
              </li>
              {!showRefugeeDistributionFeatures && (
                <li
                  className="Footer-companyLinkItem"
                  data-testid="Footer-link-help"
                >
                  <a className="Footer-link" target="_blank" href={url("help")}>
                    {t("components.Footer.more.help")}
                  </a>
                </li>
              )}
              {showRefugeeDistributionFeatures && (
                <li
                  className="Footer-companyLinkItem"
                  data-testid="Footer-link-helpCenter"
                >
                  <a
                    className="Footer-link"
                    target="_blank"
                    href={url("helpCenter")}
                  >
                    {t("components.Footer.more.helpCenter")}
                  </a>
                </li>
              )}
              <li className="Footer-companyLinkItem">
                <a className="Footer-link" target="_blank" href={url("blog")}>
                  {t("components.Footer.more.hub")}
                </a>
              </li>
              {showAffiliateProgramLink && (
                <li className="Footer-companyLinkItem">
                  <a
                    className="Footer-link"
                    target="_blank"
                    href={url("affiliateProgram")}
                    data-testid="Footer-link-affiliateProgram"
                  >
                    {t("components.Footer.more.affiliateProgram")}
                  </a>
                </li>
              )}
              <li className="Footer-companyLinkItem">
                <a
                  className="Footer-link"
                  target="_blank"
                  href={url("landlords")}
                  onClick={() => {
                    tracker.events.trackLandlordIntentClicked({
                      triggerLocation: LANDLORD_INTENT_LOCATION.FOOTER,
                    });
                  }}
                >
                  {t("components.Footer.more.landlords")}
                </a>
              </li>
            </ul>
          </div>

          <div className="Footer-categories">
            <Accordion customClassName="FooterAccordion">
              {!showRefugeeDistributionFeatures && (
                <Accordion.Section title={t("components.Footer.destinations")}>
                  <Accordion.Content>
                    <Destinations />
                  </Accordion.Content>
                </Accordion.Section>
              )}
              <Accordion.Section title={t("components.Footer.company")}>
                <Accordion.Content>
                  <ul className="Footer-accordionSection">
                    <li className="Footer-accordionListItem Footer-listItem--noMarginTop">
                      <a className="Footer-link" href={url("about")}>
                        {t("components.Footer.more.about")}
                      </a>
                    </li>
                    <li className="Footer-accordionListItem">
                      <a className="Footer-link" href={jobsLinkUrl}>
                        {t("components.Footer.more.jobs")}
                      </a>
                    </li>
                    {!showRefugeeDistributionFeatures && (
                      <li
                        className="Footer-accordionListItem"
                        data-testid="Footer-link-help"
                      >
                        <a className="Footer-link" href={url("help")}>
                          {t("components.Footer.more.help")}
                        </a>
                      </li>
                    )}
                    {showRefugeeDistributionFeatures && (
                      <li
                        className="Footer-accordionListItem"
                        data-testid="Footer-link-helpCenter"
                      >
                        <a className="Footer-link" href={url("helpCenter")}>
                          {t("components.Footer.more.helpCenter")}
                        </a>
                      </li>
                    )}
                    <li className="Footer-accordionListItem">
                      <a className="Footer-link" href={url("blog")}>
                        {t("components.Footer.more.hub")}
                      </a>
                    </li>
                    {showAffiliateProgramLink && (
                      <li className="Footer-accordionListItem">
                        <a
                          className="Footer-link"
                          href={url("affiliateProgram")}
                        >
                          {t("components.Footer.more.affiliateProgram")}
                        </a>
                      </li>
                    )}
                    <li className="Footer-accordionListItem">
                      <a
                        className="Footer-link"
                        href={url("landlords")}
                        onClick={() => {
                          tracker.events.trackLandlordIntentClicked({
                            triggerLocation: LANDLORD_INTENT_LOCATION.FOOTER,
                          });
                        }}
                      >
                        {t("components.Footer.more.landlords")}
                      </a>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Section>
              <Accordion.Section title={t("components.Footer.social")}>
                <Accordion.Content>
                  <ul className="Footer-accordionSection">
                    <li className="Footer-accordionListItem Footer-listItem--noMarginTop">
                      <a className="Footer-link" href={contactEmailHref}>
                        <img
                          loading="lazy"
                          alt="email"
                          src="/public/images/footer-icons/email.svg"
                          className="Footer-socialIcon"
                        />{" "}
                        Email
                      </a>
                    </li>
                    <li className="Footer-accordionListItem">
                      <a className="Footer-link" href={contactPhoneHref}>
                        <img
                          loading="lazy"
                          alt="phone"
                          src="/public/images/footer-icons/phone.svg"
                          className="Footer-socialIcon"
                        />{" "}
                        {formattedContactPhone}
                      </a>
                    </li>
                    <li className="Footer-accordionListItem">
                      <a
                        className="Footer-link"
                        href="https://www.instagram.com/wunderflats/"
                      >
                        <img
                          loading="lazy"
                          alt="instagram"
                          src="/public/images/footer-icons/insta.svg"
                          className="Footer-socialIcon"
                        />{" "}
                        Instagram
                      </a>
                    </li>
                    <li className="Footer-accordionListItem">
                      <a
                        className="Footer-link"
                        href="https://www.facebook.com/wunderflats/"
                      >
                        <img
                          loading="lazy"
                          alt="facebook"
                          src="/public/images/footer-icons/facebook_w.svg"
                          className="Footer-socialIcon"
                        />{" "}
                        Facebook
                      </a>
                    </li>
                    <li className="Footer-accordionListItem">
                      <a
                        className="Footer-link"
                        href="https://www.linkedin.com/company/wunderflats/"
                        rel="noopener noreferrer"
                      >
                        <img
                          loading="lazy"
                          alt="linkedin"
                          src="/public/images/footer-icons/linkedin_w.svg"
                          className="Footer-socialIcon"
                        />{" "}
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Section>
            </Accordion>
          </div>
          {showRefugeeDistributionFeatures && (
            <div className="Footer-refugeeSupport Footer-refugeeSupport--mobile">
              <a className="Footer-link" href={refugeeSupportContactPhone}>
                <img
                  loading="lazy"
                  alt="phone"
                  src="/public/images/footer-icons/phone-white.svg"
                />
                {t("contactDetails.refugeeSupport.buttonText")}
              </a>
            </div>
          )}
          <div className="Footer-socialAndLegal">
            <ul className="Footer-social">
              <li className="Footer-socialLinkItem--desktop">
                <a
                  className="Footer-link"
                  data-testid="Footer-contactEmailHref"
                  href={contactEmailHref}
                >
                  <img
                    loading="lazy"
                    alt="email"
                    src="/public/images/footer-icons/email.svg"
                    className="Footer-socialIcon"
                  />
                </a>
              </li>
              {!showRefugeeDistributionFeatures && (
                <li className="Footer-socialLinkItem--desktop">
                  <a
                    className="Footer-link"
                    data-testid="Footer-contactPhoneHref"
                    href={contactPhoneHref}
                  >
                    <img
                      loading="lazy"
                      alt="phone"
                      src="/public/images/footer-icons/phone.svg"
                      className="Footer-socialIcon"
                    />
                  </a>
                </li>
              )}
              <li className="Footer-socialLinkItem--desktop">
                <a
                  className="Footer-link"
                  href="https://www.instagram.com/wunderflats/"
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    src="/public/images/footer-icons/insta.svg"
                    alt="instagram"
                    className="Footer-socialIcon"
                  />
                </a>
              </li>
              <li className="Footer-socialLinkItem--desktop">
                <a
                  className="Footer-link"
                  href="https://www.facebook.com/wunderflats/"
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    alt="facebook"
                    src="/public/images/footer-icons/facebook_w.svg"
                    className="Footer-socialIcon"
                  />
                </a>
              </li>
              <li className="Footer-socialLinkItem--desktop Footer-socialLinkItem--noMarginRight">
                <a
                  className="Footer-link"
                  href="https://www.linkedin.com/company/wunderflats/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    loading="lazy"
                    alt="linkedin"
                    src="/public/images/footer-icons/linkedin_w.svg"
                    className="Footer-socialIcon"
                  />
                </a>
              </li>
              {showRefugeeDistributionFeatures && (
                <li className="Footer-socialLinkItem--desktop Footer-refugeeSupport">
                  <a className="Footer-link" href={refugeeSupportContactPhone}>
                    <img
                      loading="lazy"
                      alt="phone"
                      src="/public/images/footer-icons/phone-white.svg"
                      className="Footer-socialIcon"
                    />
                    {t("contactDetails.refugeeSupport.buttonText")}
                  </a>
                </li>
              )}
            </ul>
            <ul className="Footer-legal">
              <li className="Footer-legalItem Footer-listItem--noMarginTop">
                <a className="Footer-link" target="_blank" href={url("tos")}>
                  {t("components.Footer.tos")}
                </a>
              </li>
              <li className="Footer-legalItem">
                <a
                  className="Footer-link"
                  target="_blank"
                  href={url("privacypolicy")}
                >
                  {t("components.Footer.privacyPolicy")}
                </a>
              </li>
              <li className="Footer-legalItem">
                <a
                  className="Footer-link"
                  target="_blank"
                  href={url("impressum")}
                >
                  {t("components.Footer.imprint")}
                </a>
              </li>
              <li className="Footer-legalItem">
                <a
                  className="Footer-link"
                  href={`${url("impressum")}#patent-notice`}
                  target="_blank"
                >
                  {t("components.Footer.patentNotice")}
                </a>
              </li>
              <li className="Footer-legalItem Footer-copyright">
                {t("components.Footer.copyright", {
                  currentYear: new Date().getFullYear(),
                })}
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  },
);

Footer.propTypes = propTypes;

export default Footer;
