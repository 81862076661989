import gql from "./gql";

const factory = (http) => {
  return {
    async getSavedFilters() {
      const response = await http.post("/graphql", {
        query: gql`
          query {
            getListingFilters {
              _id
              name
              query {
                from
                to
                minPrice
                amenities
                minAccommodates
                totalSingleBeds
                totalDoubleBeds
                homeType
                minSize
                maxPrice
                smokingRules
                petRules
                noDeposit
                minRooms
                bbox
                search
              }
              regionSlugOrBBox
            }
          }
        `,
      });

      if (!response.data || !response.data.getListingFilters) {
        return null;
      }

      return response.data.getListingFilters;
    },

    async createSavedFilter(filterInput) {
      const {
        data: { createListingFilter },
      } = await http.post("/graphql", {
        query: gql`
          mutation ($input: CreateListingFilterInput!) {
            createListingFilter(input: $input) {
              _id
              name
              query {
                from
                to
                minPrice
                amenities
                minAccommodates
                totalSingleBeds
                totalDoubleBeds
                homeType
                minSize
                maxPrice
                smokingRules
                petRules
                noDeposit
                minRooms
                bbox
                search
              }
              regionSlugOrBBox
            }
          }
        `,
        variables: {
          input: filterInput,
        },
      });

      return createListingFilter;
    },

    async deleteSavedFilter(filterId) {
      const {
        data: { deleteListingFilter },
      } = await http.post("/graphql", {
        query: gql`
          mutation ($id: ObjectId!) {
            deleteListingFilter(id: $id) {
              success
            }
          }
        `,
        variables: {
          id: filterId,
        },
      });

      return deleteListingFilter.success;
    },
  };
};

export default factory;
