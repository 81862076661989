import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link as ReactRouterLink } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import "./Button.scss";
const commonPropTypes = {
  colorVariant: PropTypes.oneOf([
    "primary",
    "secondary",
    "secondaryBlue",
    "negative",
    "brandPurple",
    "white",
    "text",
  ]),
  widthVariant: PropTypes.oneOf(["fullWidth", "growWithText"]),
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  className: PropTypes.string,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  spinnerProps: PropTypes.shape({
    showOverlay: PropTypes.bool,
    className: PropTypes.string,
  }),
};
const onlyBasePropTypes = {
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
    .isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
};
const onlyLinkPropTypes = {
  href: PropTypes.string.isRequired,
};
const onlyClientSideLinkPropTypes = {
  to: PropTypes.string.isRequired,
};
const ClickableBase = forwardRef(
  (
    {
      element: BaseElement,
      widthVariant = "growWithText",
      colorVariant = "primary",
      size = "large",
      className,
      children,
      dataTestId,
      ...props
    },
    ref,
  ) => {
    return (
      <BaseElement
        className={classnames(
          className,
          "Button",
          `Button--${widthVariant}`,
          `Button--${colorVariant}`,
          `Button--${size}`,
        )}
        ref={ref}
        data-testid={dataTestId}
        {...props}
      >
        {children}
      </BaseElement>
    );
  },
);
ClickableBase.propTypes = { ...commonPropTypes, ...onlyBasePropTypes };
const Button = forwardRef(
  (
    {
      loading,
      disabled,
      spinnerProps,
      colorVariant,
      children,
      className,
      dataTestId,
      ...props
    },
    ref,
  ) => {
    const getLoadingSpinnerColor = (btnColorVariant) => {
      if (btnColorVariant === "secondary" || btnColorVariant === "white") {
        return "#575D64";
      }

      return "#fff";
    };

    return (
      <ClickableBase
        element="button"
        className={classnames(className, {
          "Button--loading": loading,
          "Button--disabled": disabled,
        })}
        disabled={loading || disabled}
        ref={ref}
        colorVariant={colorVariant}
        dataTestId={dataTestId}
        {...props}
      >
        {loading ? (
          <LoadingSpinner
            showOverlay={false}
            {...spinnerProps}
            color={getLoadingSpinnerColor(colorVariant)}
          />
        ) : (
          children
        )}
      </ClickableBase>
    );
  },
);
export { Button };
Button.propTypes = { ...commonPropTypes };

export const Link = forwardRef(
  ({ children, className, disabled, ...props }, ref) => {
    return (
      <ClickableBase
        element="a"
        ref={ref}
        style={
          disabled
            ? {
                pointerEvents: "none",
              }
            : undefined
        }
        className={classnames(className, {
          "Button--disabled": disabled,
        })}
        {...props}
      >
        {children}
      </ClickableBase>
    );
  },
);
Link.propTypes = { ...commonPropTypes, ...onlyLinkPropTypes };

export const ClientSideLink = forwardRef(
  ({ children, className, disabled, ...props }, ref) => (
    <ClickableBase
      element={ReactRouterLink}
      ref={ref}
      style={
        disabled
          ? {
              pointerEvents: "none",
            }
          : undefined
      }
      className={classnames(className, {
        "Button--disabled": disabled,
      })}
      {...props}
    >
      {children}
    </ClickableBase>
  ),
);
ClientSideLink.propTypes = {
  ...commonPropTypes,
  ...onlyClientSideLinkPropTypes,
};
